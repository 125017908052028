<template>
  <div class="wrapper">
    <van-button @click="add" type="primary">提交</van-button>
    <van-radio-group class="type-group" v-model="type">
      <van-radio name="母乳">母乳</van-radio>
      <van-radio name="奶瓶">奶瓶</van-radio>
      <van-radio name="尿布小">尿布小</van-radio>
      <van-radio name="尿布大">尿布大</van-radio>
      <van-radio name="睡觉">睡觉</van-radio>
    </van-radio-group>
    <h2>开始时间</h2>
    <van-datetime-picker :show-toolbar="false" v-model="startTime"
      type="time"
      title="选择时间" />
    <h2>结束时间</h2>
    <van-datetime-picker :show-toolbar="false" v-model="endTime"
      type="time"
      title="选择时间" />
    <van-button @click="add" type="primary">提交</van-button>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { Toast, Dialog } from 'vant'
export default {
  data () {
    return {
      type: '母乳',
      startTime: dayjs().format('HH:mm'),
      endTime: dayjs().format('HH:mm')
    }
  },
  methods: {
    add () {
      Dialog.confirm({
        title: '确认提交吗',
        message: `${this.type}\n${this.startTime}~${this.endTime}`
      }).then(() => {
        const Store = this.AV.Object.extend('Todo')
        const store = new Store()
        store.set('type', this.type)
        store.set('startTime', this.startTime)
        store.set('endTime', this.endTime)
        store.save().then((todo) => {
          Toast(`保存成功。objectId：${todo.id}`)
        }, (error) => {
          console.log(error)
        })
      }).catch(() => {
        // on cancel
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.wrapper {
  padding: 0 20px;
}
.type-group {
  ::v-deep .van-radio {
    margin: 20px 0;
    background: #f3f3f3;
    padding:10px 0;
  }
}
</style>
