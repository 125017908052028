<template>
  <div id="app">
    <router-view/>
    <van-tabbar v-model="active">
      <van-tabbar-item name="home" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item name="list" icon="search">历史</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data () {
    return {
      active: this.$route.name
    }
  },
  watch: {
    $route (n) {
      this.active = n.name
    },
    active (n) {
      this.$router.push(`/${n}`)
    }
  }
}
</script>
<style lang="scss" scoped>
#app {
  padding-bottom: 150px;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}
</style>
