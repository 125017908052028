import Vue from 'vue'
import App from './App.vue'
import router from './router'
import AV from 'leancloud-storage'
import './assets/css/base.css'
import Vant from 'vant'
import 'vant/lib/index.css'
Vue.config.productionTip = false
Vue.use(Vant)
AV.init({
  appId: 'OKcAMFXOuzcLF59HL7NpUSTB-gzGzoHsz',
  appKey: 'USzhOmcXr5BacDpwyqGWC6Eo'
})
Vue.prototype.AV = AV
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
